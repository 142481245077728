.benefit__section {
    padding: 6rem 10rem;
    margin-bottom: -250rem !important;
}

.benefit__left-div {
    transform: translateY(80px);
}

.benefit__img {
    transform: scale(0.9);
}

/* .benefit__section-left {
    position: relative;
    transform: scale(1.2) translateX(50px);
} */




.benefit__img-bottom-circle,
.benefit__img-top-circle {
    width: 80%;
}

.benefit__img-top-circle {
    transform: rotate(180deg) translateY(-160px);
}

.benefit__modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-65%, -30%);
    width: 60%;
    z-index: -3;
}

.benefit__img-wrapper {
    position: relative;
    animation: rotateGirlImage 5s linear infinite;
}

@keyframes rotateGirlImage {
    0%, 100% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(8deg);
    }
}


.benefit__img-inner-circle {
    width: 280px;
    height: 280px;
    border-radius: 280px;
    border: 25px solid #D1E1EC;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-73%, -20%);
    z-index: -4;
}







/* .benefit__section-right {
    position: relative;
    transform: translateY(150px);
} */

.benefit__number-path {
    width: 362.323px;
    height: 350px;
    position: absolute;
    left: -125px;
}




.benefit__card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: self-start;
    gap: 40px;
    position: absolute;
}

.benefit__card {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.benefit__number-bubble {
    min-width: 58px;
    height: 60px;
    border-radius: 1000px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0.00) 100%), #2B6E85;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
}

.benefit__card-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
}

.benefit__card-heading {
    padding: 0;
    margin: 0;
    color: #4B4B4B;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    /* 18.2px */
}

.benefit__card-text {
    color: #4B4B4B;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    /* 15.6px */
}

.benefit__card:nth-child(2),
.benefit__card:nth-child(3) {
    margin-left: 50px;
}





@media screen and (min-width:240px) {
    .benefit__section {
        padding: 3rem 2rem;
        margin-bottom: -11rem !important;
    }

    .benefit__left-div {
        transform: translateY(0px);
    }

    .benefit__img {
        transform: scale(0.7);
    }

    .benefit__card-wrapper {
        transform: translateY(120px);
        gap: 5px;  
        width: "100%";
        overflow: hidden;
    }

    .benefit__number-path {
        display: none;
    }

    .benefit__section-right {
        /* position: relative; */
        transform: translateY(-150px);
    }

    .benefit__card {
        /* transform: scale(0.6);
        margin-bottom: -40px; */
        transform: scale(0.6) translateX(-80px);
        margin-bottom: -30px;
        width: 400px;
    }

    .benefit__card:nth-child(2),
    .benefit__card:nth-child(3) {
        margin-left: 0px;
    }
}

@media screen and (min-width:576px) {

    .benefit__img {
        transform: scale(0.6);
    }

    .benefit__card-wrapper {
        gap: 15px;
    }

    .benefit__card {
        transform: scale(0.65);
        margin-bottom: -30px;
    }
}

@media screen and (min-width:870px) {

    .benefit__section {
        margin-bottom: 2rem !important;
    }

    .benefit__img {
        transform: scale(0.6);
    }

    .benefit__card {
        transform: scale(0.95);
        margin-bottom: -15px;
        gap: 25px;
    }
}
@media screen and (min-width:900px) {
    .benefit__card-wrapper {
        transform: translateY(100px) translateX(200px);
    }
    .benefit__card {
        transform: scale(1.1);
        margin-bottom: 15px;
        gap: 25px;
    }
}

@media screen and (min-width:1000px) {

    .benefit__section {
        margin-bottom: -15rem !important;
    }
    .benefit__card-wrapper {
        transform: translateY(180px) translateX(0px);
    }

    .benefit__card:nth-child(2),
    .benefit__card:nth-child(3) {
        margin-left: 50px;
    }

    .benefit__img {
        transform: scale(0.8);
    }

    .benefit__card {
        transform: scale(0.95);
        margin-bottom: -5px;
        gap: 25px;
    }
}

@media screen and (min-width:1152px) {

    .benefit__section {
        margin-bottom: -15rem !important;
    }

    .benefit__card-wrapper {
        transform: translateY(270px) translateX(-100px);
        gap: 35px;
    }

    .benefit__card {
        transform: scale(0.85) translateX(70px) translateY(-20px);
        margin-bottom: -20px;
        gap: 25px;
    }

    .benefit__number-path {
        display: block;
        transform: translateY(280px) translateX(-30px);
    }
}

@media screen and (min-width:1340px) {

    .benefit__section {
        /* margin-bottom: -25rem !important; */
    }

    .benefit__card-wrapper {
        transform: translateY(280px) translateX(-100px);
        gap: 40px;
    }
    .benefit__card {
        transform: scale(1) translateX(100px);
        gap: 25px;
    }

    .benefit__number-path {
        transform: translateY(330px) translateX(0px);
    }

}

/* .container-fluid {
    padding-bottom:  300px;
  }


  @media screen and (max-width: 780px) {
    .container-fluid {
      padding-bottom: 0;  
    }
  } */