.top-navbar {
    background: #66B7D1;
    height: 32px;
    display: flex;
    align-items: center;
}

.top-nav-data {
    display: inline-flex;
    align-items: center;
    justify-content: left;
    gap: 8px;
}

.top-nav-number,
.top-nav-mail {
    color: #FFF;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}

.top-nav-buttons {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.top-nav-button {
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #FFF;
}

@media screen and (max-width:400px) {
    .top-navbar {
        display: none;
    }
}















.navbar {
    background: #CDDFEB;
    display: flex;
    height: 72px;
    padding: 10px 0px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
}

.navbar-collapse{
    z-index: 999999999;
}

.nav-logo {
    height: 42px;
}

/* .navbar-nav {
    display: flex;
    height: 42px;
    padding: 10px 0px;
    justify-content: center;
    align-items: center;
    gap: 12px;
} */

.nav-item {
    height: 42px;
    padding: 10px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}




.nav-link {
    color: #2E5C69 !important;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.nav-link.active {
    position: relative;
    overflow: hidden;
    transition: all 0.3s;
}

.nav-link.active::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #2E5C69;
    left: 0;
    bottom: 0;
}

.nav-btn {
    border-radius: 4px;
    background: #2A7086;
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    transition: all 0.3s;
}

.nav-btn:hover {
    background: #2E5C69;
    color: #fff;
}

.search-btn {
    margin-left: 16px;
}

.search-btn-icon {
    width: 18px;
    height: 18px;
}

@media screen and (max-width:1199px) {
    .navbar {
        padding: 10px 0px;
    }

    .nav-item {
        display: block;
        width: initial;
    }

    .navbar-collapse {
        background-color: #2E5C69;
    }

    .nav-link {
        color: #fff !important;
        margin: 5px 10px;
    }

    .nav-buttons {
        margin: 15px 10px;
    }
}

@media screen and (max-width:400px) {
    .navbar-contaiener {
        padding-left: 0px;
        padding-right: 0px;
    }

    .navbar-brand {
        transform: translateX(12px);
    }

    .navbar-toggler {
        transform: translateX(-12px);
    }
}



section {
    padding: 6rem 4rem;
}

.section__title {
    color: #2B6E85;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
    text-align: center;
    position: relative;
}

.section__title::after {
    content: '';
    position: absolute;
    bottom: -5px;
    right: 50%;
    transform: translateX(50%);
    width: 36px;
    height: 1px;
    background: #2B6E85;
}


.section__text {
    color: #4B4B4B;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
    text-align: center;
    margin-top: 20px;
    text-transform: capitalize;
}







.header {
    background-image: url(../../assets/images/bg.jpg);
    /* height: 100vh; */
    width: 100% !important;
    background-repeat: no-repeat;
    object-fit: cover;
    object-position: center;
    background-position: right top -80px;
    position: relative;
}

/* @media screen and (max-width:  430px) {
    .header {
        width: 106% !important;
    }
}
@media screen and (max-width:  385px) {
    .header {
        width: 120% !important;
    }
} */
@media screen and (max-width: 1100px) {
    .header {
        background-position: left top;
    }
}

.hero__section {
    padding: 8rem 5rem 10rem;
}

.hero__sub-heading {
    color: #2E5C69;
    font-family: Poppins;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    text-transform: capitalize;
}

.hero__heading {
    color: #2B6E85;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.hero__text {
    color: #2E5C69;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.hero__btn {
    background: #2A7086;
    color: #FFF;
    font-family: Poppins;
    font-size: 24px;
    padding: 12px 32px;
    font-weight: 500;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 0;
    outline: 0;
    margin-top: 2rem;
    transition: transform 0.3s ease;
}

.hero__btn:hover {
    transform: scale(1.05); /* Increase the size of the card on hover */
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
}
.header__section__bottom img {
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100% !important;
    /* width: 1600px !important; */
}



/* For small devices */
@media screen and (min-width:240px) {
    .hero__heading {
        font-size: 25px;
    }
    .hero__sub-heading{
        font-size: 14px;
    }
    .hero__section {
        padding: 4rem 2rem 4rem;
    }
    .hero__text{
        font-size: 13px;
    }
    .hero__btn{
        font-size: 14px;
        padding: 10px 20px;
        margin-top: 1rem;
    }
}

/* For medium devices */
@media screen and (min-width:576px) {
    .hero__section {
        padding: 5rem 3rem 5rem;
    }
    .hero__sub-heading{
        font-size: 14px;
    }
    .hero__heading {
        font-size: 30px;
    }
    .hero__text{
        font-size: 15px;
    }
    .hero__btn{
        font-size: 16px;
        padding: 10px 24px;
        margin-top: 1.2rem;

    }
}

@media screen and (min-width:768px) {
    .hero__section {
        padding: 5rem 4rem 7rem;
    }
    .hero__sub-heading{
        font-size: 15px;
    }
    .hero__heading {
        font-size: 30px;
    }
    .hero__text{
        font-size: 16px;
    }
    .hero__btn{
        font-size: 18px;
        padding: 10px 26px;
        margin-top: 1.4rem;

    }
}

/* For large devices */
@media screen and (min-width:1152px) {
    .hero__section {
        padding: 7rem 5rem 9rem;
    }
    .hero__sub-heading{
        font-size: 17px;
    }
    .hero__heading {
        font-size: 35px;
    }
    .hero__text{
        font-size: 18px;
    }
    .hero__btn{
        font-size: 20px;
        padding: 10px 30px;
        margin-top: 1.6rem;

    }
}

@media screen and (min-width:1340px) {
    .hero__section {
        padding: 7rem 5rem 9rem;
    }
    .hero__sub-heading{
        font-size: 18px;
    }
    .hero__heading {
        font-size: 38px;
    }
    .hero__text{
        font-size: 18px;
    }
    .hero__btn{
        font-size: 22px;
        padding: 10px 30px;
        margin-top: 1.8rem;
    }
}