.news__section {
    background: #E7F5FF;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.news__circles {
    position: absolute;
    left: 1%;
    top: 10%;
}

.news__plane {
    position: absolute;
    right: 5%;
    top: 45%;
}

.news__data {
    width: 40%;
    z-index: 999;

}

.news__heading {
    color: #2B6E85;
    font-family: Poppins;
    font-size: 40px;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center !important;
}

.news__text {
    color: #737373;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
    /* 20.8px */
}

.email__bar {
    display: flex;
    align-items: center;
    border-radius: 1000px;
    border: 0.3px solid #2B6E85;
    background: #FFF;
    height: 3rem;
}

.email__icon-wrapper {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.email__icon {
    width: 20px;
}

.email__input {
    width: 65%;
    background-color: transparent;
    border: none;
    outline: none;
    font-family: Poppins;
    color: rgba(115, 115, 115, 1);
    margin-right: 5%;
}

.email__button {
    height: 100%;
    border-radius: 0px 1000px 1000px 0px;
    background: #2B6E85;
    color: #fff;
    border: none;
    outline: none;
    /* padding: 0rem 1rem; */
    width: 25%;
}



@media screen and (min-width:240px) {
    .news__data {
        width: 85%;
    }
    .news__heading{
        font-size: 22px;
    }
    .news__text{
        font-size: 12px;
    }
    .email__input,
    .email__button{
        font-size: 12px ;
    }
    .news__circles,
    .news__plane{
        width: 30px;
    }
}

@media screen and (min-width:576px) {
    .news__data {
        width: 70%;
    }
    .news__heading{
        font-size: 29px;
    }
    .news__text{
        font-size: 15px;
    }
    .email__input,
    .email__button{
        font-size: 13px ;
    }
    .news__circles,
    .news__plane{
        width: 60px;
    }
}

@media screen and (min-width:870px) {
    .news__data {
        width: 50%;
    }
    .news__heading{
        font-size: 32px;
    }
    .news__text{
        font-size: 15px;
    }
    .news__circles,
    .news__plane{
        width: 120px;
    }

}


@media screen and (min-width:1152px) {
    .news__data {
        width: 50%;
    }
    .news__heading{
        font-size: 36px;
    }
    .news__circles,
    .news__plane{
        width: 140px;
    }

}

@media screen and (min-width:1340px) {
    .news__data {
        width: 40%;
    }
    .news__heading{
        font-size: 38px;
    }
    .news__circles,
    .news__plane{
        width: 170px;
    }

}