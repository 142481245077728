.rating__section{
    padding: 6rem 4rem;
}
.rating {
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.rating__wrapper {
    display: grid;
    grid-template-columns: repeat(4, 288px);
    gap: 16px;
    flex-shrink: 0;
}

.rating__card {
    display: flex;
    width: 288px;
    height: 120px;
    padding: 16px;
    gap: 24px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 10px;
    background: #2B6E85;
    box-shadow: 1px 1px 10px 1px grey;
    transition: transform 0.3s ease;
}
.rating__card:hover {
    transform: scale(1.05); /* Increase the size of the card on hover */
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
}

.rating__badge {
    display: flex;
    width: 88px;
    height: 88px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10000px;
    background: #FFF;
    color: #2B6E85;
}

.rating__icon {
    width: 45px;
    height: 45px;
    fill: #2B6E85;
}

.rating__data {
    display: flex;
    width: 150px;
    height: 88px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    transform: translateY(12px);
    
}

.rating__number {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #FFF;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.rating__title {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    transform: translateY(-20px);
}


/* For small devices */
@media screen and (min-width:240px) {
    .rating__section{
        padding: 3rem 4rem;
    }
    .rating__wrapper {
        grid-template-columns: repeat(2, min-content);
        gap: 8px;
        transform: scale(0.8)
    }
    .rating__card {
        height: min-content;
        width: min-content;
        padding: 6px;
        gap: 6px;
    }

    .rating__badge {
        height: 50px;
        width: 50px;
    }

    .rating__data {
        width: 120px;
        height: 65px;
    }

    .rating__number {
        font-size: 25px;
    }

    .rating__title {
        font-size: 10px;
    }
}

/* For medium devices */
@media screen and (min-width:576px) {
    .rating__card {
        padding: 8px;
        gap: 12px;
    }
    .rating__badge {
        height: 55px;
        width: 55px;
    }

    .rating__data {
        width: 125px;
        height: 70px;
    }

    .rating__number {
        font-size: 30px;
    }

}

@media screen and (min-width:768px) {
    .rating__section{
        padding: 4rem 4rem;
    }
    .rating__wrapper {
        grid-template-columns: repeat(2, min-content);
        gap: 26px;
    }

    .rating__badge {
        height: 60px;
        width: 60px;
        gap: 8px;
    }
    .rating__card {
        padding: 12px;
        gap: 14px;
    }
    .rating__data {
        width: 130px;
        height: 75px;
    }

    .rating__number {
        font-size: 35px;
    }

    .rating__title {
        font-size: 11px;
    }
}

/* For large devices */
@media screen and (min-width:1152px) {
    .rating__section{
        padding: 5rem 4rem;
    }
    .rating__wrapper {
        grid-template-columns: repeat(3, min-content);
    }

    .rating__badge {
        height: 70px;
        width: 70px;
        gap: 9px;
    }
    .rating__card {
        padding: 14px;
        gap: 20px;
    }
    .rating__data {
        width: 135px;
        height: 78px;
    }

    .rating__number {
        font-size: 40px;
    }

    .rating__title {
        font-size: 12px;
    }
}

@media screen and (min-width:1340px) {
    .rating__wrapper {
        grid-template-columns: repeat(4, min-content);
    }

    .rating__card {
        height: min-content;
        width: min-content;
        padding: 15px;
        gap: 22px;
    }

    .rating__badge {
        height: 77px;
        width: 77px;
        gap: 9px;
    }

    .rating__data {
        width: 140px;
        height: 80px;
    }

    .rating__number {
        font-size: 45px;
    }

    .rating__title {
        font-size: 13px;
    }
}