.Margfix {
  color: #0d0c1d;
}

.size {
  font-size: 0.9em;
  color: #0d0c1d;
}

.bottm {
  padding-top: 70px;
  padding-bottom: 200px;
  width: 85%;
  padding-left: 80px;
}

.addedimg {
  border-radius: 8px;
  width: 400px;
  -moz-box-shadow: 10px 10px 5px #ccc;
  -webkit-box-shadow: 10px 10px 5px #ccc;
  box-shadow: 10px 10px 5px #ccc;
  margin-bottom: -200px;
}

.adjuss {
  padding-bottom: 15px;
}

.addedimg1 {
  border-radius: 8px;
  margin-top: 180px;
  margin-left: 12px;

  -moz-box-shadow: 10px 10px 5px #ccc;
  -webkit-box-shadow: 10px 10px 5px #ccc;
  box-shadow: 10px 10px 5px #ccc;
}

.textint {
  font-size: 1.2em;
  font-weight: 400;
  padding: 0 50px;
  color: #000000;
}

.row {
  display: flex;
}

.column {
  flex: 25%;
  padding: 5px;

  background-color: rgb(201, 255, 255);
  margin: 135px 60px 120px 100px;
  border-radius: 8px;

  border: 2px solid #2b6e85;
}

@media screen and (max-width: 375px) {
  .addedimg {
    margin-bottom: -100px;
    margin-top: 30px;
    margin-left: -42px;
    width: 280px;
  }

  .size {
    font-size: 0.64em;
  }
  .addedimg1 {
    margin-bottom: -175px;
    margin-top: 0px;
  }

  .addedimg1 {
    width: 247px;
    margin-left: -25px;
  }

  .spi {
    margin-top: 100px;
    font-size: 0.8em;
  }
  .initiative-text {
    font-size: 1.2em;
  }
  .cl1 {
    margin-right: px;
  }
  .Margfix {
    overflow-x: hidden;
  }

  .cl1,
  .cl2 {
    margin-left: 60px;
  }

  .cl1 {
    margin-bottom: -100px;
  }
  .adjuss {
    font-size: 0.52em;
    margin-left: -30px;
  }
  .cl2 {
    margin-top: -0.1px;
  }
  .adjuss1 {
    margin-left: -30px;
  }
}
/* Clear floats after image containers */
