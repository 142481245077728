/* BlogDetail.module.css */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    max-width: 800px; /* Adjust the width as needed */
    padding: 20px;
    text-align: center;
    padding-top: 80px;
  }
  
  .title {
    font-size: 2rem; /* Larger font size for the title */
    color: #2B6E85; /* Dark color for better readability */
    margin-bottom: 20px;
    text-transform: uppercase; /* Optional style */
  }
  
  .content {
    font-size: 1rem;
    color: #666; /* Lighter color for the content */
  }
  
 
  .image {
    width: 100%;
    max-width: 800px; /* Adjust based on your preference */
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  