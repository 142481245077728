.who__section {
    padding: 6rem 4rem;
}

.who__img-div {
    height: 500px !important;
}

.who__img-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.who__section-left {
    border-radius: 529px;
    border: 1px dashed #2B6E85;
    width: 529px;
    height: 528px;
    position: relative;
}



.who__section-right {
    padding: 48px 64px 32px 64px;
}

/* Existing CSS */

.who__img-1 {
    width: 447px;
    position: absolute;
    top: -10%;
    left: -5%;
    animation: rotateImage1 5s linear infinite;
}

.who__img-2 {
    width: 343px;
    position: absolute;
    bottom: -3%;
    right: -5%;
    animation: rotateImage2 10s linear infinite;
}

/* Keyframes for circular animation */
@keyframes rotateImage1 {
    0%, 100% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(10deg);
    }
}

@keyframes rotateImage2 {
    0%, 100% {
        transform: rotate(10deg);
    }
    50% {
        transform: rotate(-10deg);
    }
}

/* Updated CSS for image positions */

.who__img-1 {
    width: 447px;
    position: absolute;
    top: -10%;
    left: -5%;
    animation: rotateImage1 5s linear infinite;
}

.who__img-2 {
    width: 343px;
    position: absolute;
    bottom: -3%;
    right: -5%;
    animation: rotateImage2 5s linear infinite;
}

.who__sub-heading {
    color: #2B6E85;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
}

.who__heading {
    color: #000;
    font-family: Poppins;
    font-size: 36px;
    font-weight: 600;
    text-transform: capitalize;
}

.who__text {
    color: #2A2A2A;
    text-align: justify;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    /* 21px */
}

.who__cards-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-top: 32px;
}

.who__card {
    display: flex;
    align-items: flex-start;
    gap: 30px;
}

.who__card-img-wrapper {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 3px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    background: rgba(43, 110, 133, 0.12);
}

.who__card-img {
    width: 26px;
    height: 26px;
    flex-shrink: 0;
    stroke-width: 2px;
    stroke: #2B6E85;
}

.who__card-heading {
    color: #2B6E85;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin: 0;
}

.who__card-text {
    color: #2A2A2A;
    text-align: justify;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 18px */
    margin: 0;
}

.who__card-read {
    color: #66B7D1;
    font-style: italic;
    font-weight: 500;
    cursor: pointer;
}











/* For small devices */
@media screen and (min-width:240px) {

    .who__img-wrapper {
        transform: scale(0.9)  translate(-55%, -55%);
    }
    .who__section-left {
        transform: scale(0.5) !important;
    }

    .who__img-div {
        height: 300px !important;
    }

    .who__section-right {
        padding: 0px;
    }

    .who__section {
        padding: 1rem 1.5rem;
    }
}


/* For medium devices */
@media screen and (min-width:576px) {
    .who__img-wrapper {
        transform: scale(1.3)  translate(-40%, -35%);
    }
}

@media screen and (min-width:870px) {
    .who__img-wrapper {
        /* transform: scale(1.3)  translate(-40%, -35%) !important; */
    }
    .who__img-div {
        height: 400px !important;
    }
    .who__section-right {
        padding: 48px 64px 32px 64px;
    }

    .who__section {
        padding: 2.5rem 2rem;
    }
}




/* For large devices */
@media screen and (min-width:1152px) {}
.who__img-wrapper {
    /* transform: scale(1.5)translate(-35%, -10%) !important; */
}
/* .who__section-right {
    padding: 48px 64px 64npm startpx 64px;
} */
@media screen and (min-width:1340px) {
    .who__section {
        padding: 5rem 4rem;
    }
    .who__img-wrapper {
        transform: scale(1.5)  translate(-35%, -15%);
    }
}