.footer__section {
    background: #F2FAFF;
    padding: 6rem 8rem 3rem 8rem;
}

.footer__content:nth-child(1) {
    padding-right: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
}

.footer__social{
    display: flex;
    gap: 1rem;
}

.footer__icon {
    width: 20px;
    height: 20px;
}

.footer__text {
    color: #737373;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 130%;
    /* 15.6px */
}

.footer__heading {
    color: #2B6E85;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
}

.footer__links {
    display: flex;
    flex-direction: column;
    gap: 4px;

}

.footer__link {
    color: #737373;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
    text-decoration: none;
}


@media screen and (min-width:240px) {
    .footer__section {
        padding: 6rem 2rem 3rem ;
    }
    .footer__social{
        gap: .5rem;
    }
    
    .footer__content{
        margin-top: 2rem;
    }
    .footer__heading{
        text-align: left;
    }
    .footer__link{
        text-align: left;
        margin-top: -4px;
    }

}

@media screen and (min-width:576px) {
    .footer__section {
        padding: 6rem 4rem 3rem;
    }

}

@media screen and (min-width:870px) {
    .footer__section {
        padding: 6rem 5rem 3rem;
    }
    .footer__social{
        gap: .8rem;
    }
    .footer__link{
        margin-top: -2px;
    }

}


@media screen and (min-width:1152px) {
    .footer__section {
        padding: 6rem 7rem 3rem;
    }
    .footer__social{
        gap: 1rem;
    }
    .footer__heading{
        text-align: center;
    }
    .footer__link{
        text-align: center;
        margin-top: 0px;
    }
}

@media screen and (min-width:1340px) {
    .footer__section {
        padding: 6rem 8rem 3rem;
    }

}