

.testimonial__section {
    background-image: url(../../assets/images/network-bg-2.png);
    object-fit: cover;
}

@media screen and (min-width:240px) {
    .testimonial__section{
        margin-top: 400px;
    }
}

.Linkstyle{
    text-decoration: none;
    color:#274B9C
}

.testimonial__wrapper {
    padding: 4rem;
}

.testimonial__slider-wrapper {
    margin-top: 2rem !important;
}

.testimonial__right-wrapper,
.testimonial__left-wrapper {
    position: relative;
}




.testimonial__person {
    position: absolute;
}

.person__1 {
    left: 0;
    top: 0;
}

.person__4 {
    right: 0;
    top: 0;
}

.person__3 {
    left: 0;
    bottom: 0;
}

.person__6 {
    right: 0;
    bottom: 0;
}

.person__2,
.person__5 {
    right: 40%;
    bottom: 40%;
}



.testimonial__slider-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 2rem;
}

.carousel-inner {
    border-radius: 27px;
    background: #FFF;
    box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
    position: relative;
    overflow: visible !important;
    transition: transform 0.3s ease;
}

.carousel-item.active {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 13px;
    padding: 5rem 3rem 4rem;

}

.carousel-inner:hover {
    transform: scale(1.02); /* Increase the size of the card on hover */
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
}

.testimonial__slider-heading {
    color: #333;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.testimonial__slider-text {
    color: #4B4B4B;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.testimonial__slider-img {
    position: absolute;
    top: -30px;
    right: 50%;
    transform: translateX(50%);
    width: 100px;
    height: 100px;
    border-radius: 106px;
    border: 8px solid #274B9C;
}

.slider-quote {
    position: absolute;
    top: -20px;
    left: 0;
}

.carousel-indicators {
    z-index: 999 !important;
}

.carousel-indicators button {

    height: 10px !important;
    width: 10px !important;
    padding: 0px !important;
    padding: 0px !important;
    border-radius: 100px;
    background-color: rgba(1, 85, 170) !important;
}

.carousel-indicators [data-bs-target] {
    border-radius: 100%;
    /* add border-radius */
}

.carousel-indicators .active {
    opacity: 1;
    transform: scale(1.3);
}




/* For small devices */
@media screen and (min-width:240px) {
    .testimonial__wrapper{
        padding: 4rem 1rem;
    }
    .testimonial__slider-wrapper{
        padding: 2rem .5rem;
    }
    .testimonial__right-wrapper,
    .testimonial__left-wrapper {
        display: none;
    }
    .carousel-item.active{
        padding: 3rem 2rem 2rem;
        gap: 5px;
    }
    .testimonial__slider-heading{
        font-size: 20px;
    }
    .testimonial__slider-text{
        font-size: 15px;
        margin-bottom: 15px;
    }
    .testimonial__slider-img{
        height: 70px;
        width: 70px;
        border: 5px solid #274B9C;
    }
    .slider-quote{
        transform: scale(0.6);
        top: -40px;
        left: -10px;
    }
    .testimonial__slider-wrapper{
        margin: 0px !important;
    }

}

/* For medium devices */
@media screen and (min-width:576px) {

    .testimonial__right-wrapper,
    .testimonial__left-wrapper {
        display: none;
    }
}

@media screen and (min-width:768px) {

    .testimonial__right-wrapper,
    .testimonial__left-wrapper {
        display: none;
    }
    .slider__wrapper{
        padding: 0rem 15%;
    }
}

@media screen and (min-width: 1000px) {
    .slider__wrapper{
        padding: 0;
    }
}

/* For large devices */
@media screen and (min-width:1152px) {
    .testimonial__right-wrapper,
    .testimonial__left-wrapper {
        display: block;
    }

    .slider__wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
    }

}

@media screen and (min-width:1340px) {
    .testimonial__wrapper{
        padding: 4rem;
    }
    .testimonial__slider-wrapper{
        padding: 2rem .5rem;
        margin-top: 2px !important;
    }

    .carousel-item.active{
        gap: 13px;
        padding: 5rem 3rem 4rem;
    }
    .testimonial__slider-heading{
        font-size: 40px;
    }
    .testimonial__slider-text{
        font-size: 20px;
    }
    .testimonial__slider-img{
        width: 100px;
        height: 100px;
        border: 8px solid #274B9C;
    }
    .slider-quote{
        transform: scale(1);
        top: -20px;
        left: 0;
    }

}