/* ContentSection.css */

.content-section {
    padding: 40px 0;
    /* background-color: #f4f4f4; */
  }
  
  .section-title {
    font-size: 24px;
    font-weight: bold;
    color: #2B6E85;
    margin-bottom: 20px;
  }
  
  .support-list {
    list-style-type: none;
    padding-left: 0;
  }
  
  .support-list li {
    margin-bottom: 10px;
    font-size: 16px;
    /* color: #666; */
  }
  
  .check-icon {
    margin-right: 10px;
    color: #2B6E85; /* Change the color to your preference */
  }
  