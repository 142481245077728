.privacy-policy {
    font-family: Arial, Helvetica, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 20px;
    margin-top: 20px;
  }
  
  p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  
  ul {
    list-style: disc;
    margin-left: 20px;
    margin-bottom: 10px;
  }
  
  li {
    margin-bottom: 5px;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    h1 {
      font-size: 22px;
    }
  
    h2 {
      font-size: 18px;
    }
  
    p {
      font-size: 14px;
    }
  }
  