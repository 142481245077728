/* categoriesSidebar.css */

aside {
  width: 250px;
  padding: 20px;
  background-color: #2A7086;
  border: 1px solid #ddd;
  /* border-radius: 8px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

aside h2 {
  color: #fff;
  margin-bottom: 15px;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.search-bar {
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.categories-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: auto; /* Make the list scrollable */
  max-height: 300px; /* Adjust height as needed */
}

aside li {
  margin-bottom: 10px;
  transition: all 0.3s ease;
  border-bottom: 1px solid #eee; /* Stylish border after each category */
  padding-bottom: 10px;
}

aside li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

aside li a:hover {
  color: #007bff;
  text-decoration: underline;
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

}


/* Existing styles */

.dropdown-toggle {
  display: none; /* Hide by default */
  background-color: #2A7086;
  color: white;
  padding: 10px 15px;
  border: none;
  width: 100%;
  text-align: left;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
  aside {
    position: fixed;
    left: 0;
    width: 100%;
    padding: 10px 20px;
    background-color: #2A7086;
    z-index: 1000;
    transition: top 0.3s;
    margin-top: 0px;
  }

  .dropdown-header {
    width: 100%;
    margin-top: 0px;
  }

  .dropdown-toggle {
    display: block;
     /* Show the dropdown toggle button on mobile */
  }

  .categories-list, .search-bar {
    display: none; /* Hide by default on mobile */
  }

  aside.open .categories-list,
  aside.open .search-bar {
    display: block; /* Show when sidebar is open */
    padding-top: 10px;
  }
  .categories-title {
    display: none;
  }

  .categories-list {
    position: static; /* Override absolute positioning */
    background-color: transparent; /* Override background color */
    box-shadow: none; /* Remove box shadow */
  }

  aside li a {
    color: white;
    padding: 10px;
    display: block;
  }
}