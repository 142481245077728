/* TeamSection.css */

.team-section {
    /* background-color: hsl(0, 0%, 97%); */
    padding: 60px 0;
  }
  
  .section-title {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    color: #2B6E85;
    margin-bottom: 40px;
  }
  
  .team-member {
    text-align: center;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
  }
  
  .team-member:hover {
    transform: translateY(-5px);
  }
  
  .team-member-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 auto 20px;
    border: 5px solid #ffffff;
  }
  
  .team-member-name {
    font-size: 24px;
    font-weight: bold;
    color: #2B6E85;
  }
  
  .team-member-role {
    font-size: 18px;
    color: #2A2A2A;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 992px) {
    .team-member {
      margin-bottom: 30px;
    }
  }
  