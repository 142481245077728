.MainDiv {
  overflow: hidden;
}
.blog__section {
  padding: 2rem 4rem 8rem 4rem;
}
.blog__section-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}
.blog__cards-wrapper {
  max-width: 100vw;
  min-width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.blog__card-area {
  display: grid;
  padding: 4rem 9rem 0rem 9rem;
  grid-template-columns: repeat(2);
}
.blog__card {
  display: inline-flex;
  padding: 16px 16px;
  align-items: center;
  gap: 24px;
  border-radius: 8px;
  background: #fff;
  border: 0.2px solid #99999953;
  transition: all 0.4s ease-in-out;
  margin: 0.5rem;
  width: 72%;
}
.blog__card:hover {
  border: 0;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}
.blog__card-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}
.blog__card-img-wrapper1 {
  width: auto;
  height: auto;
  overflow: hidden;
  position: relative;
  box-shadow: none;
  margin: none;
}
.blog__card-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: none;
  margin: none;
}
.image-Blog {
  box-shadow: none;
  margin: none;
}
.blog__card-category {
  display: flex;
  width: 93.881px;
  padding: 4px 8px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: rgba(43, 110, 133, 0.16);
  color: rgba(0, 0, 0, 0.86);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}
.blog__card-heading {
  color: rgba(0, 0, 0, 0.86);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}

.blog__card-bottom-bar {
  display: flex;
  width: 284px;
  justify-content: space-between;
  align-items: flex-start;
}
.blog__card-bottom-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.blog__card-icon {
  width: 16px;
  height: 16px;
  fill: #565656;
}
.blog__card-bottom-text {
  color: #626262;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 0;
  transform: translateY(8px);
} /* For small devices */
@media screen and (min-width: 240px) {
  .blog__card-area,
  .blog__section-wrapper,
  .blog__cards-wrapper {
    max-width: 100vw;
    min-width: 100vw;
  }
  
  .blog__card-area {
    grid-template-columns: repeat(1);
  }
  .blog__section {
    padding: 2rem 0rem 4rem;
  }
  .blog__card {
    transform: scale(0.8) translateX(0rem);
    margin-bottom: -80px;
  }
}
@media screen and (max-width: 576px) {
  .blog__cards-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  }

  .blog__card {
    width: 100%; 
    flex-direction: column;
   align-items: center;
   justify-content: center;
  margin-left: -1px;
  }

  .blog__card-area {
    padding: 2rem 1rem; 
  }
}/* For medium devices */
@media screen and (min-width: 576px) {
  .blog__card {
    transform: scale(0.65);
    margin-bottom: -50px;
  }
}
@media screen and (min-width: 768px) {
  .blog__card {
    transform: scale(0.75);
    margin-bottom: -30px;
  }
} /* For large devices */
@media screen and (min-width: 1152px) {
  .blog__card {
    transform: scale(0.85);
    margin-bottom: -15px;
  }
  .blog__card-area {
    grid-template-columns: repeat(2);
  }
}
@media screen and (min-width: 1340px) {
  .blog__card {
    transform: scale(0.95);
    margin-bottom: 0px;
  }
  .blog__card-area {
    grid-template-columns: repeat(2);
  }
  .blog__section {
    padding: 2rem 4rem 8rem 4rem;
  }
}



