

.blog__sectionM {
    padding: 2rem 4rem 8rem 4rem;
    
}

.blog__section-wrapperM {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
}

.blog__cards-wrapper{

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.blog__card-area {
    display: grid;
    padding: 4rem 9rem 0rem 9rem;
    grid-template-columns: repeat(2);
}



.blog__cardM {
    display: inline-flex;
    padding: 16px 14px;
    align-items: center;
    gap: 24px;
    border-radius: 8px;
    background: #FFF;
    border: 0.2px solid #99999953;
    transition: all 0.2s ease-in-out;
    margin: .5rem;
}

.blog__card:hover {
    border: 0;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
}

.blog__card-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.blog__card-img-wrapper {
    padding: 4px;
    width: 176px;
    height: 200x;
    border-radius: 4px;

    overflow: hidden;
    position: relative;
}

.blog__card-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.blog__card-category {
    display: flex;
    width: 93.881px;
    padding: 4px 8px;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: rgba(43, 110, 133, 0.16);
    color: rgba(0, 0, 0, 0.86);
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
}

.blog__card-heading {
    color: rgba(0, 0, 0, 0.86);
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
}

.blog__card-bottom-bar {
    display: flex;
    width: 284px;
    justify-content: space-between;
    align-items: flex-start;
}


.blog__card-bottom-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

.blog__card-icon {
    width: 16px;
    height: 16px;
    fill: #565656;
}

.blog__card-bottom-text {
    color: #626262;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 0;
    transform: translateY(8px);
}



/* For small devices */
@media screen and (max-width:576px) {
    

    .blog__card-area,
    .blog__section-wrapper,
    .blog__cards-wrapper{
        max-width: 90vw;
        min-width: 90vw; 
    }

    .blog__card-area {
        grid-template-columns: repeat(1);
    }
    .blog__sectionM {
        padding: 2rem 0rem 4rem;
    }
    .blog__cardM{
        transform: scale(0.7) translateX(0rem);  
        margin-bottom: -100px; 
        padding: 5px;
        margin: 0px;
    }
}

/* For medium devices */
@media screen and (min-width:576px) {
    .blog__card{
        transform: scale(0.65);  
        margin-bottom: -50px;  
    }
}

@media screen and (min-width:768px) {
    .blog__card{
        transform: scale(0.75);  
        margin-bottom: -30px; 
    }
}

/* For large devices */
@media screen and (min-width:1152px) {
    .blog__card{
        transform: scale(0.85);  
        margin-bottom: -15px; 
    }
    .blog__card-area {
        grid-template-columns: repeat(2);
    }
}

@media screen and (min-width:1340px) {
    .blog__card{
        transform: scale(0.95);
        margin-bottom: 0px;
    }
    .blog__card-area {
        grid-template-columns: repeat(2);
    }
    .blog__section {
        padding: 2rem 4rem 8rem 4rem;
    }


}