@import url('https://fonts.googleapis.com/css2?family=Inter&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    font-family: 'Poppins', sans-serif;
}

html, body{
    overflow-x: hidden;
}



* {
    font-family: 'Inter', sans-serif;
}


section {
    padding: 6rem 4rem;
}

.section__title {
    color: #2B6E85;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
    text-align: center;
    position: relative;
}

.section__title::after {
    content: '';
    position: absolute;
    bottom: -5px;
    right: 50%;
    transform: translateX(50%);
    width: 36px;
    height: 1px;
    background: #2B6E85;
}


.section__text {
    color: #4B4B4B;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
    text-align: center;
    margin-top: 20px;
    text-transform: capitalize;
}



@media screen and (min-width:240px) {

.section__title{
    font-size: 16px;
}
.section__text{
    font-size: 10px;
}
}

@media screen and (min-width:576px) {

    .section__title{
        font-size: 16px;
    }
    .section__text{
        font-size: 11px;
    }
}

@media screen and (min-width:870px) {

    .section__title{
        font-size: 16px;
    }

}

@media screen and (min-width:980px) {

    .section__title{
        font-size: 18px;
    }
    .section__text{
        font-size: 13px;
    }
}
@media screen and (min-width:1152px) {
    .section__title{
        font-size: 19px;
    }
    .section__text{
        font-size: 14px;
    }
}

@media screen and (min-width:1340px) {
    .section__title{
        font-size: 20px;
    }
    .section__text{
        font-size: 16px;
    }

}