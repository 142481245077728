/* CSS for FAQ Item */
/* CSS for FAQ Item */
.faq-item {
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    padding: 20px;
    margin: 0 auto; /* Center the item horizontally */
    max-width: 1000px; /* Adjust the maximum width as needed */
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  
  /* Style for FAQ Question */
  .faq-question {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  /* Style for FAQ Answer */
  .faq-answer {
    font-size: 16px;
    color: #555;
    line-height: 1.4;
  }
  