

.offer__section {
    background: linear-gradient(180deg, rgba(227, 247, 255, 0.80) 0%, rgba(160, 217, 239, 0.70)40%, rgba(160, 217, 239, 0.70) 100%), url(../../assets/images/offer-bg.png), rgb(255, 255, 255) 7px -0.083px / 99.514% 106.09% no-repeat;
    padding: 8rem 6rem;

}

.offer__section-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.offer__card-wrapper {
    margin-top: 5rem;
    display: grid;
    grid-template-columns: repeat(3, min-content);
    gap: 40px;
}



.offer__card {
    display: flex;
    width: 400px;
    padding: 36px 28px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.20);
    transition: all 0.2s;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.offer__card::before {
    content: '';
    background-color: #2B6E85;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 0%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: all 0.3s;
    z-index: 1;
}

.offer__card:hover{
    transform: scale(1.01); /* Increase the size of the card on hover */
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
}
.offer__card:hover.offer__card::before {
    height: 5px;
}

.offer__card-content {
    display: flex;
    align-items: flex-start;
    gap: 16px;
}

.offer__card-img-wrapper {
    display: flex;
    max-width: 110px;
    min-width: 64px;
    height: 64px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1px;
    border-radius: 1000px;
    border: 6px solid rgba(0, 7, 180, 0.03);
    border: 6px solid rgb(226, 226, 226);
    background: #2B6E85;
    position: relative;
}

.offer__card-img {
    position: absolute;
    width: 34px;
    height: 36px;
}

.flex__card-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.flex__card-heading {
    color: #2B6E85;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin: 0;
}

.flex__card-text {
    color: #4B4B4B;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
    margin: 0;
}



@media screen and (min-width:240px) {
    .offer__card-wrapper {
        grid-template-columns: repeat(1, 1fr);
        gap: 0px;
        margin-top: 2rem;
    }
    .offer__card{
        transform: scale(0.7);
    }
}

@media screen and (min-width:576px) {
    .offer__card{
        transform: scale(0.85);
    }
}

@media screen and (min-width:870px) {
    .offer__card-wrapper {
        grid-template-columns: repeat(2, min-content);
        gap: 30px;
    }
    .offer__card{
        transform: scale(0.8);
    }
}

@media screen and (min-width:1152px) {
    .offer__card-wrapper {
        grid-template-columns: repeat(2, min-content);
    }
    .offer__card{
        transform: scale(0.85);
    }
}

@media screen and (min-width:1340px) {
    .offer__card-wrapper {
        grid-template-columns: repeat(3, min-content);
    }
    .offer__card{
        transform: scale(0.95);
    }
}






