.support__according-wrapper {
    padding: 2rem 2rem 4rem;
}

.according__heading {
    color: #2B6E85;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    padding-right: 8rem;
}

.accordion {
    margin-top: 30px;
}

.accordion-button {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    box-shadow: none !important;
}

.accordion-button:not(.collapsed)::after {
    color: #fff;
    background-image: url('');
    transform: rotate(-45deg);
}

.accordion-button::after {
    content: '+';
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
    background-image: url('');
}

.accordion-button:not(.collapsed) {
    border-radius: 4px 4px 0px 0px;
    background: #2B6E85;
    color: #fff;
}

.support__image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
}






@media screen and (min-width:240px) {
    section {
        padding: 2rem 1rem;
    }
    .support__according-wrapper {
        padding: 2rem .5rem 2rem;
    }

    .according__heading {
        padding-right: 2rem;
        font-size: 18px;
    }

    .accordion-button {
        font-size: 13.5px;
    }

    .accordion-body {
        font-size: 12.5px;
    }

}

@media screen and (min-width:576px) {
    section {
        padding: 3rem 2rem;
    }
    .according__heading {
        padding-right: 3rem;
        font-size: 21px;
    }
    .accordion-button {
        font-size: 14px;
    }

    .accordion-body {
        font-size: 13px;
    }
}

@media screen and (min-width:870px) {
    section {
        padding: 4rem 3rem;
    }
    .according__heading {
        padding-right: 4rem;
        font-size: 24px;
    }
    .accordion-button {
        font-size: 14.5px;
    }

    .accordion-body {
        font-size: 13.5px;
    }

}


@media screen and (min-width:1152px) {
    section {
        padding: 6rem 4rem;
    }
    .according__heading {
        padding-right: 7rem;
        font-size: 28px;
    }
}

@media screen and (min-width:1340px) {
    .support__according-wrapper {
        padding: 2rem 2rem 4rem;
    }

    .according__heading {
        font-size: 30px;
        padding-right: 7rem;
    }

}