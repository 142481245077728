.margg {
  margin-top: 100px;
}
.spacc {
  margin: 140px;
}

.biggest {
  font-size: 22px;
  color: #0d0c1d;
  overflow: hidden;
}

.biggest {
  font-size: 22px;
  color: #0d0c1d;
  overflow: hidden;
}
.card1 {
  background-color: #2b6e85;
  color: white;
  padding: 0.5rem;
  height: 100%;
  border-radius: 8px;
  font-weight: 500;
  font-size: 0.9em;
  -moz-box-shadow: 10px 10px 5px #ccc;
  -webkit-box-shadow: 10px 10px 5px #ccc;
  box-shadow: 10px 10px 5px #ccc;

  overflow-wrap: break-word;
}

.cardss {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 1rem;
  margin: 10px 60px;
}
@media (min-width: 900px) {
  .cardss {
    grid-template-columns: repeat(3, 1fr);
  }
}

.magr {
  margin-top: 50px;
  margin-bottom: -100px;
}

.minus {
  margin-top: -140px;
}

.Margin11l li {
  margin-bottom: 8px;
  padding: 5px;
  font-size: 0.75em;
  margin-left: -30px;
}
.margg li {
  font-size: 1em;
}

.adjuss {
  color: #0d0c1d;
}

@media screen and (max-width: 375px) {
  .Margin11l {
    font-size: 1em;
  }
  .minus {
    margin-top: 5px;
  }
  .magr {
    margin-bottom: 10px;
  }

  .bottm {
    margin-bottom: -70px;
  }
}
#container2 {
  margin-top: 150px;
  margin-bottom: 200px;
  margin-left: 220px;
}
#container {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}
.block {
  padding: 15px;
  width: 5;
  height: auto;
  margin: 12px;
  display: inline-block;
  margin-left: 120px;

  overflow-wrap: break-word;
  -moz-box-shadow: 10px 10px 5px #ccc;
  -webkit-box-shadow: 10px 10px 5px #ccc;
  box-shadow: 10px 10px 5px #ccc;
  border-radius: 8px;

  border: 2px solid #2b6e85;
}
.magr {
  margin-bottom: -180px;
}
.pi {
  margin-top: -100px;
}
.biggest {
  margin-top: -40px;
}
.clrr {
  padding-top: 50px;
}
.testr {
  border: 1px solid #2b6e85;
}
@media screen and (max-width: 375px) {
  #container2 {
    margin-top: 200px;
    margin-bottom: 200px;
    margin-left: 0;
  }

  .mggt {
    text-align: center;
  }

  .card1 {
    font-size: 0.72em;
  }

  .bottm {
    margin-left: -16.5px;
    margin-top: -50px;
    margin-bottom: -10px;
  }

  .addedimg {
    width: 400px;
    height: auto;
  }
  .testr {
    border: 1px solid #2b6e85;
  }
}
@media screen and (max-width: 375px) {
  #container2 {
    margin-top: 200px;
    margin-bottom: 200px;
    margin-left: 0;
  }

  .mggt {
    text-align: center;
  }

  .card1 {
    font-size: 0.72em;
  }

  .bottm {
    margin-left: -16.5px;
    margin-top: -50px;
    margin-bottom: 20px;
  }
  .cardss {
    margin-bottom: 150px;
  }
  .clrr {
    margin-left: -5px;
  }
  .img-fluid {
    width: none;
  }
  .addedimg22 {
    width: 280px !important;
    height: auto;
    margin-left: -49px;
    margin-top: -20px;
  }

  .adjuss {
    font-size: 0.6em;
  }

  .kkl {
    margin-top: -80px;
  }
  .magr {
    margin-bottom: -70px;
  }
  .biggest {
    margin-top: -70px;
  }
  .minus {
    margin-top: 85px;
    margin-bottom: -120px;
  }

  .block {
    margin-left: -0px;
  }
  .bottm {
    margin-left: -5px;
  }
}

.mggt {
  text-align: center;
}
.mggt1 {
  font-size: 1.6em;
  margin-bottom: 20px;
}
.movedown {
  margin-top: 20px;
}
.addedimg22 {
  width: 400px;
}

.marginup {
  margin-top: -120px;
}
